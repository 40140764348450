@import "./functions";

.bg_app_primary {
  background: color(primary);
}
.bg_app_secondary {
  background: color(secondary) !important;
}
.bg_app_outlet {
  background: color(outlet) !important;
}
.bg_app_light {
  background: color(light) !important;
}
.bg_app_green {
  background: color(green_1);
}
.bg_app_danger {
  background: color(danger);
}
.bg_app_grey {
  background: color(_grey) !important;
}
.bg_none {
  background: none !important;
}
.text_app_dark {
  color: color(dark);
}
.text_app_danger {
  color: color(danger);
}
.text_app_primary {
  color: color(primary) !important;
}
.text_transparent {
  color: transparent;
}
.text_app_secondary {
  color: color(secondary) !important;
}
.bg_green_2 {
  color: color(green_2) !important;
}
.text_app_black {
  color: color(_black) !important;
}
.text_app_grey {
  color: color(_grey) !important;
}

.text_app_green {
  color: color(green_1);
}
.text-white {
  color: color(light);
}

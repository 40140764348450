
@mixin flex{
    display: flex;
}
@mixin grid {
    display: block;
}
@mixin padding_small {
    padding: 1.5rem 1rem;
}
@mixin box_padding($top, $right, $bottom, $left) {
    padding: $top $right $bottom $left;
}
@mixin padding_medium {
    padding: 2rem 1.5rem;
}
@mixin padding_large {
    padding: 2.5rem 2rem;
}
@mixin shadow_light{
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.02);
}
@mixin shadow_md{
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03);
}
@mixin shadow_deep{
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
}
@mixin family_barlow{
    font-family: 'Barlow', sans-serif;
}
@mixin border_style($position, $size, $color) {
    @if $position == 'bottom' {
        border-bottom: $size solid $color !important;
    }
    @if $position == 'top' {
        border-top: $size solid $color !important;
    }
    @if $position == 'left' {
        border-left: $size solid $color !important;
    }
    @if $position == 'right' {
        border-right: $size solid $color !important;
    }
    @if $position == 'round' {
        border: $size solid $color !important;
    }
}
@mixin image_size($width, $height) {
    width: $width;
    height: $height;
}
@mixin grid($flex) {
    @if $flex {
        @include flex;
    } @else {
        @include grid;
    }
}


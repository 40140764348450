@import './mixins';
@import './functions';

label{
    font-size: 14px;
}
textarea{
    padding: 0.385rem 1.2rem !important;
    font-size: font(f_14) !important;
}
input,select{
    height: calc(2em + 0.8rem + 1px) !important;
    padding: 0.5rem 1.2rem !important;
    color: color(input) !important;
    font-size: font(f_14) !important;
    width: 100%;
    &:focus,&:active{
        outline: none;
        outline-width: 0;
    }
    &::placeholder{
        color: color(input) !important;
    }
    &.border_none{
        border: none;
    }
}
.select_group{
    // select{
    //     padding: 0.385rem 1.2rem !important;
    // }
    mat-icon{
        position: absolute;
        right: .8rem;
        top: .6rem;
    }
}
//DISABLE AUTO ZOOM IN
@media screen and (max-width: 767px) {
    input, select, textarea {
        font-size: 16px !important;
    }
}

//VALIDATION ERROR STYLING
input.ng-touched.ng-invalid, 
select.ng-touched.ng-invalid {
  border: border(b_2) solid color(light_danger) !important;
}
input.ng-valid,
select.ng-valid{
    border: border(b_2) solid color(green_4) !important;
}
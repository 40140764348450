@import "./mixins";
@import "./functions";

.intro {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.page_content {
  background: #fff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
}
.verified {
  color: rgb(15, 212, 133);
}
.verified,
.is_active,
.deactivated,
.pending,
.unverified,
.rejected,
.started {
  padding: 3px 10px;
  border-radius: 5px;
  display: flex;
  width: max-content;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 11px;
  mat-icon {
    color: #adadad;
    margin-right: 5px;
    height: 18px;
    width: 18px;
    font-size: 18px;
  }
}
.verified,
.is_active {
  background: #e8faf3;
  color: #505050;
  mat-icon {
    color: rgb(15, 212, 133);
  }
}
.pending {
  background: #f7ecd26b;
  color: #505050;
  mat-icon {
    color: orange;
  }
}
.rejected {
  background: rgb(189, 95, 95);
  color: #fff;
  mat-icon {
    color: rgb(189, 95, 95);
  }
}

.selected {
  cursor: pointer;
}
.mat-table {
  background: none;
}
.mat-form-field input {
  border-bottom: 1px solid #ddd;
}

.mat-header-cell {
  color: color(text-black);
}
.mat-header-cell span {
  color: color(text-black);
  padding: 5px 10px;
}
.mat-cell button.disabled {
  background: #ddd;
  cursor: default;
}
.mat-cell button {
  background: color(secondary);
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 2px 7px;
}
.fa-user-edit {
  cursor: pointer;
  color: color(primary);
}

.fa-trash {
  color: red;
  cursor: pointer;
}
.mat-table .mat-cell {
  border-bottom: none;
}
.mat-footer-row,
.mat-header-row,
.mat-row {
  display: flex;
  border-width: 0;
  border-bottom-width: 0;
  border-style: none;
  align-items: center;
  box-sizing: border-box;
}
.mat-table .mat-row:last-child {
  border-bottom: none;
}
.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  background: #fff;
  border-radius: 50%;
}
.mat-paginator {
  margin-top: 3vh;
  display: block;
  margin-top: 0;
  padding: 3px 0;
  color: #380e7f;
}

.filter {
  padding: 0 2%;
}
.filter input {
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
}
.mat-elevation-z8 {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.search {
  padding: 1rem 2rem;
  background: #fff;
  border: 0.5px solid #d9ebb7b8 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 1rem;
  border-radius: 20px !important;
}
.reload {
  position: absolute;
  margin-top: -2.8rem;
  right: 8%;
  transform: rotateY(180deg);
  cursor: pointer;
}
select {
  border-radius: 15px;
}
.input-group-text {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  min-width: 120px;
  border: none;
}
@media screen and (max-width: 960px) {
  .verified,
  .is_active,
  .pending,
  .unverified {
    float: right;
  }
  .mat-table {
    border: 0;
    vertical-align: middle;
  }

  .mat-table caption {
    font-size: 0.5em;
  }
  .mat-cell,
  .mat-footer-cell {
    color: color(Dgrey);
  }
  .mat-header-row {
    display: none;
  }
  // .mat-table .mat-header-cell {

  //   border: 10px solid;
  //   clip: rect(0 0 0 0);
  //   height: 1px;
  //   margin: -1px;
  //   padding: 0;
  //   position: absolute;
  //   width: 1px;
  // }

  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
  }
  /*
      .mat-table .mat-row:nth-child(even) {background: #CCC}
      .mat-table .mat-row:nth-child(odd) {background: #FFF}
      */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    color: 000;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    height: 30px;
    margin-bottom: 4%;
  }
  .mat-table .mat-cell:before {
    /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;

    font-size: 0.85em;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }
  .mat-table .mat-header-cell span {
    display: none;
  }
}

@media (max-width: 478px) {
  mat-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-header-cell:first-of-type {
    //display: none;
    padding-left: 0;
  }
}
@media (max-width: 599px) {
  .tags {
    flex-wrap: wrap;
  }
  .action_box {
    display: flex;
    justify-content: flex-start;
  }
}

.cdk-overlay-pane {
  // background-color: #fff !important;
  td.start-date {
    background-color: red;
  }

  td.in-range {
    background-color: red;
  }

  td.in-range {
    background-color: red;
  }
}
.mat-list-item-content {
  width: 100% !important;
}

@import "./mixins";
@import "./functions";

.border_none {
  border: none !important;
}
.border_primary_2x {
  border: border(b_2) solid color(green_1);
}
.border_primary_1x {
  @include border_style(round, border(b_1), color(primary));
}
.border_1x_light {
  border: border(b_1) solid color(light);
}
.border_danger_1x {
  @include border_style(round, border(b_1), color(danger));
}

.border_grey {
  @include border_style(round, border(b_1), color(_altgrey));
}
.border_selected {
  border: border(b_1) solid color(green_1);
  border-left: border(b_3) solid color(green_1);
}
.border_left_2x_primary {
  @include border_style(left, border(b_2), color(primary));
}
.border_bottom_1x_grey {
  @include border_style(bottom, border(b_1), color(_altgrey));
}
.border_left_1x_grey {
  @include border_style(left, border(b_1), color(_altgrey));
}

.mobile_links {
  .router_active {
    &::before {
      width: 3rem;
      left: 0 !important;
      right: initial !important;
    }
  }
}
.heading {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -0.7rem;
    left: 47%;
    right: 47%;
    height: 3px;
    background: color(secondary);
  }
}
.heading_2 {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -0.7rem;
    left: 0;
    width: 3rem;
    height: 3px;
    background: color(light);
  }
  &.dark_underline {
    &::before {
      background: color(secondary);
    }
  }
}
.radius_50 {
  border-radius: 50%;
}
.radius_20 {
  border-radius: 20px;
}
.radius_3 {
  border-radius: 3px;
}
.radius_5 {
  border-radius: 5px;
}
.radius_top_right_5 {
  border-top-left-radius: 5px;
}
.radius_10 {
  border-radius: 10px !important;
}

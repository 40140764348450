.card-body{
    padding-left: 5rem;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem 1rem 1rem 4rem !important;
    p{
        padding-left: 1rem;
    }
}
.card{
    padding: .8rem 0;
    border-radius: 0 !important;
    button{
        text-decoration: none !important;
    }
}

@import './mixins';
@import './functions';

mat-icon{
    &.icon_sm{
        font-size: font(f_16);
        height: 16px;
        width: 16px;
    }
    &.icon_md{
        font-size: font(f_24);
        height: 24px;
        width: 24px;
    }
    &.icon_lg{
        font-size: 30px;
        height: 30px;
        width: 30px; 
    }
    &.icon_xl{
        height: 100px;
        width: 100px;
        font-size: 100px;
    }
    &.upload_icon{
        box-shadow: 0px 5px 20px rgba(64, 122, 255, 0.3);
        width: 34px;
        height: 34px;
        font-size: 24px;
        padding-top: .3rem;
        background: #fff;
        right: 0;
        position: absolute;
    }
}
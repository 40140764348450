@import './mixins';
@import './functions';

.app_card{
    @include shadow_light;
    @include padding_large;
}
.summary{
    display: flex;
    justify-content: space-between;
    .summary_card{
        width: 23.5%;
        padding: 1.3rem 3rem;
    }
}
@media screen and (max-width: 599px) {
    .summary{
        display: block !important;
        .summary_card{
            width: 100%;
            margin-bottom: 1rem;
        }
    }

}
.candidate_card{
    padding: 1.3rem 1.5rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


//SNACKBAR STYLING
.mat-snack-bar-container {
  max-width: 100% !important;
  width: 100% !important;
  span {
    color: #fff !important;
  }
}
.mat-simple-snackbar-action button {
  color: #fff !important;
}
.error-snackbar,
.success-snackbar,
.warning-snackbar {
  z-index: 999999999 !important;
  color: #fff !important;
}
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  line-height: initial !important;
}
.error-snackbar,
.error-snackbar .mat-simple-snackbar-action {
  background-color: red;
  color: #fff !important;
}
.warning-snackbar,
.warning-snackbar .mat-simple-snackbar-action {
  background-color: orange;
  color: #fff;
}
.success-snackbar,
.success-snackbar .mat-simple-snackbar-action {
  background-color: green;
  color: #fff;
}

.error-badge {
  background-color: red;
  color: #fff !important;
  border-radius: 3px;
  width: 100%;
  padding: 0.3rem 2rem;
}

.body_padding{
    padding: 2rem 7%;
}
.padding_sm{
    padding: .2rem 1rem;
}
.pt_4{
    padding-top: 4rem;
}
.pt_2{
    padding-top: 2rem;
}
.py_2{
    padding: 2rem 0;
}
.pr_3{
    padding-right: 3rem;
}
.mb_1{
    margin-bottom: 1rem !important;
}
.mb_2{
    margin-bottom: 2rem !important;
}
.mb_3{
    margin-bottom: 3rem !important;
}
.mb_4{
    margin-bottom: 4rem !important;
}
.mb_5{
    margin-bottom: 5rem !important;
}
.mt_2{
    margin-top: 2rem;
}
.mt_3{
    margin-top: 3rem;
}
.pr_10{
    padding-right: 10rem !important;
}

.pr_7{
    padding-right: 7rem !important;
}
.pr_5{
    padding-right: 5rem !important;
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: #f4f4f4 !important;
}
@import "~material-icons/iconfont/material-icons.scss";
@import "./theme/custom-index.scss";

.cursor {
  cursor: pointer;
}
* {
  outline: none;
  outline-width: 0;
}
.wrapper {
  padding: 2rem 7%;
}

.mat-dialog-container {
  padding: 0 24px !important;
  border-radius: 5px;
}

mat-dialog-content {
  max-height: 95vh !important;
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .cdk-overlay-pane {
    max-width: 95vw !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: Mermaid;
  src: url(../assets/Myfonts/Mermaid1001.ttf) format("opentype");
  font-family: Museo;
  src: url(../assets/Myfonts/MuseoSans-300.otf) format("opentype");
  font-family: Museo-light;
  src: url(../assets/Myfonts/MuseoSans-100.otf) format("opentype");
}
h6,
h5 {
  letter-spacing: 0.05em; /* Adjust as needed */
  font-family: Mermaid;
}
p,
li,
span,
button,
small {
  font-family: Museo !important;
}
span.family-vollkorn {
  font-family: Museo !important;
}
.family_barlow {
  font-family: Museo !important;
}
.font_12 {
  font-size: font(f_12);
}
.font_14 {
  font-size: font(f_14);
}
.font_16 {
  font-size: font(f_16);
}
.font_18 {
  font-size: font(f_18);
}
.font_20 {
  font-size: font(f_20);
}
.font_24 {
  font-size: font(f_24);
}
.font_28 {
  font-size: font(f_28);
}
.font_32 {
  font-size: font(f_32);
}
.font_34 {
  font-size: font(f_34);
}
.font_36 {
  font-size: font(f_36);
}
.font_40 {
  font-size: font(f_40);
}
.font_45 {
  font-size: font(f_45);
}
.font_34 {
  font-size: font(f_34);
}
.font_48 {
  font-size: font(f_48);
}
.weight_800 {
  font-weight: weight(w_800);
}
.weight_600 {
  font-weight: weight(w_600);
}
.line_height_30 {
  line-height: 30px;
}
.line_height_20 {
  line-height: 20px;
}
.text_underline {
  text-decoration: underline;
}

@import "./functions";

.min_page {
  min-height: 100vh;
}
.position_absolute {
  position: absolute !important;
}
.flex_x_betwwen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.d_grid {
  display: grid;
}
.flex_center {
  display: flex;
  align-items: center;
}
.flex_y_center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.flex_x_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

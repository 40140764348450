@import './mixins';
@import './functions';

.cursor{
    cursor: pointer;
}
button{
    border: none;
    background: none;
    font-size: font(f_14);
    outline: none !important;
    outline-width: 0 !important;
    &:disabled{
        opacity: .5;
        cursor: not-allowed;
    }
    &.shadow_light{
        @include shadow_light;
    }
    &.shadow_deep{
        @include shadow_deep;
    }
    &.radius_5{
        border-radius: 5px;
    }
    &.padding_lg{
        padding: .7rem 2rem;
    }
    &.padding_sm{
        padding: .4rem 1.2rem;
    }
    &.banner_btn{
        padding: .5rem 1.85rem;
    }
    &.padding_xl{
        padding: .5rem 6rem;
    }
    &.padding_xsm{
        padding: .2rem .5rem;
    }
    &.fb{
        background: #395185;
    }
    &.twitter{
        background: #55ACEE;
    }
    &.ig{
        background: #D7DEED;
    }
    &.whatsapp{
        background: #41C452;
    }
    &.social{
        width: 329px;
        max-width: 100%;
    }
}

.animLine {
    position: relative;
    transform: scale(1);
    transition: .1s;
    &::before{
        position: absolute;
        content: "";
        background: linear-gradient(90deg, color(gold_2) 40%, transparent 40%);
        top: -3px;
        bottom: -3px;
        left: 0;
        right: 0;
        z-index: 1;
        border-radius: 4px;
        background-size: 200% 4px;
        background-repeat: no-repeat;
        background-position: 80% 0;
        animation: beforeLineAnim 2s linear infinite;
        animation: beforeLineAnim 2s linear infinite;
    }
  }
    
  @keyframes beforeLineAnim {
    45%,
    50% {
      background-position: -100% 0;
    }
    45% {
      transform: scale(1)
    }
    50%,
    95% {
      transform: scale(1, -1)
    }
    95% {
      background-position: 80% 0
    }
  }
  
  
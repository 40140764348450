@import "./mixins";
@import "./functions";

.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs .nav-item {
  margin-right: 2.5rem;
}
.nav-tabs .nav-link {
  display: block;
  padding: 0.5rem 0;
}
.nav-tabs .nav-link:hover {
  border: none;
}
.nav-tabs .nav-link:nth-child(1) {
  padding: 0.5rem 0;
}
.nav-tabs a {
  color: #505050 !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: color(green_1);
  background: none !important;
  border: 1px solid transparent !important;
}
.nav-tabs .nav-item.show .nav-link::before,
.nav-tabs .nav-link.active::before {
  content: "";
  background: color(green_1);
  width: 100px;
  height: 3px;
  position: absolute;
  margin-top: 1.8rem;
}
.nav-tabs .nav-link:hover {
  border: 1px solid transparent !important;
}

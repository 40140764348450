@import "./mixins";
@import "./functions";

.logo-pseudo-md {
  @include image_size(80px, 80px);
  position: relative;
  &::before {
    display: inline-block;
    content: "";
    background: url(../cbt-admin/assets/img/kashere-logo.png);
    @include image_size(80px, 80px);
    background-size: 80px 80px;
    border-radius: 10px;
    background-repeat: no-repeat;
  }
}
.logo-pseudo-sm {
  position: relative;
  @include image_size(50px, 50px);
  &::before {
    content: "";
    background: url(../cbt-admin/assets/img/kashere-logo.png);
    @include image_size(50px, 50px);
    display: inline-block;
    background-size: 50px 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
  }
}

img {
  &.profile {
    object-fit: cover;
    border-radius: 50%;
  }
  &.avatar {
    @include image_size(31px, 31px);
  }
  &.avatar_md {
    @include image_size(4rem, 4rem);
  }
  &.logo_sm {
    @include image_size(40px, auto);
  }
  &.logo_md {
    @include image_size(80px, auto);
  }
  &.profile_img {
    @include image_size(120px, 120px);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25));
  }
  &.profile_img_alt {
    @include image_size(6rem, 6rem);
    border: border(b_1) solid color(light_grey);
  }
  &.card_img {
    @include image_size(7rem, 7rem);
    border: border(b_1) solid color(light_grey);
  }
  &.logo {
    @include image_size(1.25rem, auto);
  }
  &.logo_lg {
    @include image_size(18rem, auto);
  }
}
.img_initials {
  @include image_size(31px, 31px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: font(f_12);
}
